<template>
    <div>
        <el-dialog
            :title="dialogFormTitle"
            :visible.sync="dialogFormVisible"
            :append-to-body="true"
            width="30%"
            :center="true"
            top="20vh"
            :close-on-click-modal="false"
            :before-close="() => closeDialog()"
            v-loading.fullscreen.lock="login_loading"
        >
            <div>
                <el-form
                    :model="SuppliesCategory"
                    :rules="rules"
                    ref="SuppliesCategoryEditForm"
                    label-width="100px"
                    size="small"
                >
                    <div v-if="show_type">
                        <el-form-item label="类别名" prop="name">
                            <el-input
                                v-model="SuppliesCategory.name"
                                clearable
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="所属类型" prop="name">
                            <el-select
                                v-model="SuppliesCategory.with_type"
                                filterable
                                clearable
                                placeholder="请选择类型"
                                class="with_typec"
                            >
                                <el-option
                                    v-for="(value,index) in this.$store.state.office_supplies_type"
                                    :key="value"
                                    :label="value"
                                    :value="index"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div v-else>
                        <el-descriptions
                            class="margin-top"
                            :column="1"
                            :labelStyle="{'width':'120px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>类别名</span>
                                </template>
                                <span>{{SuppliesCategory.name}}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>所属类型</span>
                                </template>
                                <span>{{SuppliesCategory.type_name}}</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <div v-if="show_type===0">
                    <el-button
                        v-if="$_has(edit_auth)"
                        type="primary"
                        plain
                        @click="$emit('show_edit')"
                        >修改</el-button
                    >
                    <el-button
                        v-if="$_has(del_auth)"
                        type="danger"
                        plain
                        @click="SuppliesCategoryDel('SuppliesCategoryEditForm')"
                        >删除</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else-if="show_type===1">
                    <el-button
                        type="success"
                        plain
                        @click="submitInsert('SuppliesCategoryEditForm')"
                        >确定</el-button
                    >
                    <el-button
                        type="warning"
                        plain
                        @click="resetForm()"
                        >重置</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >关闭</el-button
                    >
                </div>
                <div v-else>
                    <el-button
                        type="success"
                        plain
                        @click="submitEdit('SuppliesCategoryEditForm')"
                        >确定修改</el-button
                    >
                    <el-button
                        plain
                        @click="closeDialog()"
                        >取消操作</el-button
                    >
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { supplies_category_add_request,supplies_category_del_request,supplies_category_edit_request } from '@/network/office/SuppliesCategory.js'

export default {
    name: 'SuppliesCategoryEditComponent',
    data() {
        return {
            login_loading: false,
            SuppliesCategory: {
                name: '',
                with_type: '',
                type_name: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请填写类别名',
                        trigger: 'blur',
                    }
                ],
                with_type: [
                    {
                        required: true,
                        message: '请选择类型',
                        trigger: 'blur',
                    }
                ]
            }
        }
    },
    computed: {
        dialogFormTitle() {
            if(this.show_type === 0) {
                return '办公用品类别详情'
            } else if (this.show_type === 1) {
                return '添加办公用品类别'
            } else {
                return '修改办公用品类别'
            }
        },
    },
    methods: {
        closeDialog() {
            this.resetForm()
            this.$emit('exitSuppliesCategoryDialog')
        },
        submitInsert(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.SuppliesCategoryInsert(formName)
            })
        },
        SuppliesCategoryInsert(formName) {
            this.login_loading = true
            supplies_category_add_request(this.SuppliesCategory)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('添加成功!')
                        this.$emit('exitSuppliesCategoryDialog')
                        this.$emit('search')
                        this.$emit('details_row',s.result.id*1)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        resetForm() {
            this.SuppliesCategory = {
                name: '',
                with_type: '',
            }
        },
        submitEdit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }
                this.SuppliesCategoryEdit(formName)
            })
        },
        SuppliesCategoryEdit(formName) {
            this.login_loading = true
            this.SuppliesCategory.id = this.id
            supplies_category_edit_request(this.SuppliesCategory)
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('exitSuppliesCategoryDialog')
                        this.$emit('search')
                        this.$emit('details_row',this.SuppliesCategory.id)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        },
        SuppliesCategoryDel(formName) {
            this.$confirm(
                '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    supplies_category_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('删除成功!')
                                this.$emit('search')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err)
                        })
                        .finally(() => this.closeDialog())
                })
                .catch((err) => {})
        },
    },
    filters: {},
    props: {
        dialogFormVisible: Boolean,
        id: {
            type: Number,
            default() {
                return 0
            },
        },
        supplies_category_details_data: {
            type: Object,
            default() {
                return {}
            },
        },
        show_type: {
            type:Number,
            default() {
                return 0
            },
        },
        edit_auth: String,
        del_auth: String
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
    watch: {
        supplies_category_details_data(newVal) {
            this.SuppliesCategory = newVal
        },
    },
}
</script>

<style lang='less'>
.details>.el-form-item__label {
    color:cornflowerblue;
}
.with_typec {
    width: 100%;
}
</style>