import request from '../request'

//办公用品类别列表
export const supplies_category_index_request = p => {
    return request({
        method:'GET',
        url:'supplies_category/index',
        params: p
    })
}

//添加办公用品类别
export const supplies_category_add_request = d => {
    return request({
        method:'POST',
        url:'supplies_category/add',
        data: d
    })
}

//删除办公用品类别
export const supplies_category_del_request = id => {
    return request({
        method:'DELETE',
        url:'supplies_category/del',
        data: {
            id: id
        }
    })
}

//修改办公用品类别
export const supplies_category_edit_request = data => {
    return request({
        method:'PUT',
        url:'supplies_category/edit',
        data
    })
}

//获取办公用品类别详情
export const supplies_category_details_request = id => {
    return request({
        method:'GET',
        url:'supplies_category/details',
        params: {
            id
        }
    })
}