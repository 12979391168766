<template>
    <div v-loading.fullscreen.lock="loading">
        <supplies-category-search-bar-component
            add_auth="新增办公用品类别"
            @search="get_supplies_category_index"
            @addTeam="addTeam"
        ></supplies-category-search-bar-component>
        <common-table-component
            details_auth="办公用品类别详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_supplies_category"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_supplies_category_index"
        ></common-page-component>
        <supplies-category-edit-component
            edit_auth="修改办公用品类别"
            del_auth="删除办公用品类别"
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :supplies_category_details_data="fsupplies_category_details_data"
            @exitSuppliesCategoryDialog="dialogExit"
            @search="get_supplies_category_index"
            @show_edit="show_edit"
            @details_row="details_supplies_category"
        ></supplies-category-edit-component>
    </div>
</template>

<script>
import { supplies_category_index_request,supplies_category_details_request } from '@/network/office/SuppliesCategory.js'

import SuppliesCategorySearchBarComponent from '@/components/office/SuppliesCategory/SuppliesCategorySearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import SuppliesCategoryEditComponent from '@/components/office/SuppliesCategory/SuppliesCategoryEditComponent'

export default {
    name: 'SuppliesCategoryView',
    data() {
        return {
            fid: 0,
            edit_type: 0,
            fdialogFormVisible: false,
            loading: true,
            ftotal: 0,
            cond: {
                name: '',
                with_type: '',
                page: 1,
                limit: 10,
            },
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'name',
                    label: '类别名称',
                    minWidth: '150px'
                },
                {
                    prop: 'with_type',
                    label: '所属类型',
                    minWidth: '200px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: '',
                                filter_data: '固定资产设备'
                            }
                        } else if(d===1) {
                            return {
                                type: 'success',
                                filter_data: '低值易耗品'
                            }
                        } else if(d===2) {
                            return {
                                type: 'warning',
                                filter_data: '招待用品'
                            }
                        }
                    }
                },
            ],
            fsupplies_category_details_data: {},
        }
    },
    computed: {},
    methods: {
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        get_supplies_category_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.with_type = param.with_type ?? this.cond.with_type
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            supplies_category_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_supplies_category(id) {
            this.fid = id
            this.fdialogFormVisible = true
            supplies_category_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.fsupplies_category_details_data = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
    },
    filters: {},
    props: {},
    created() {
        this.get_supplies_category_index()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        SuppliesCategorySearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        SuppliesCategoryEditComponent,
    },
    watch: {},
}
</script>

<style lang='less'></style>